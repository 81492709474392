var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"p-4",attrs:{"md":"12"}},[_c('b-card',[_c('div',{staticClass:"py-2 text-center"},[_c('vuexy-logo')],1),_c('b-card-header',{staticClass:"pl-0 pt-0"},[_c('b-card-title',[_vm._v("Registrations")])],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Full Name"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Address"}},[_c('validation-provider',{attrs:{"name":"Company Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Company Address"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Company Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password"}},[_c('validation-provider',{attrs:{"vid":"password","name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"autocomplete":"off","placeholder":"Company Password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":function () { return _vm.passwordFieldType = _vm.passwordFieldType === 'password' ? 'text' : 'password'; }}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Confirm Password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:password|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{attrs:{"type":_vm.passwordFieldTypeRetype,"state":errors.length > 0 ? false : null,"autocomplete":"off","placeholder":"Password Confimation"},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":function () { return _vm.passwordFieldTypeRetype = _vm.passwordFieldTypeRetype === 'password' ? 'text' : 'password'; }}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),(_vm.form.type == 1)?_c('div',{staticClass:"pt-2"},[_c('h3',[_vm._v("Company Info")]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Company Name"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Company Name"},model:{value:(_vm.form.company.name),callback:function ($$v) {_vm.$set(_vm.form.company, "name", $$v)},expression:"form.company.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2681683077)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Company Email"}},[_c('validation-provider',{attrs:{"name":"Company Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Company Email"},model:{value:(_vm.form.company.email),callback:function ($$v) {_vm.$set(_vm.form.company, "email", $$v)},expression:"form.company.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2208077253)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Company Address"}},[_c('validation-provider',{attrs:{"name":"Company Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Company Address"},model:{value:(_vm.form.company.address),callback:function ($$v) {_vm.$set(_vm.form.company, "address", $$v)},expression:"form.company.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,977703237)})],1)],1)],1)],1):_vm._e()],1)],1),_c('div',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"12"}},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isProcessing},on:{"click":_vm.handleOk}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.isProcessing),expression:"isProcessing"}],attrs:{"small":""}}),_vm._v(" Register ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }